.mainHeader {
    text-align: center;
    font-size: xxx-large;
}

.mainContainer {
    align-items: center;
    justify-items: center;
    text-align: center;
}

.sectionHeader {
    font-size: xx-large;
    margin: 10px 8px;
}

.subSectionHeader {
    text-align: left;
    font-size: x-large;
    margin: 10px 15px;
}

.bodyContent {
    text-align: left;
    margin: 5px 8px;
    white-space: pre-line;
}

.inspo {
}

.topMargin {
    margin-top: 10px;
}

.seasonPair {
    display: flex!important;
    justify-content: center;
    max-width: 400vh;
    margin: 8px;
}

.seasonPairPic {
    height: 100%
}

.pairContent {
    text-align: center;
}

.seasonLeft {
    border-radius: 55px;
    height: 100%;
}

.seasonRight {
    height: 100%;
    border-radius: 55px;
}

.followMe {
    background-color: aquamarine;
    padding: 20px 20px;
    margin-top: 30px;
    text-align: center;
}

.colorPalette {
    width: 110px;
    margin: 10px 8px 0px 8px;
}

.followMeSub { 
    margin: 7px 0px;
}

.hairBlurb {
    margin-bottom: 12px;
}